/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { Button, Text } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useState } from 'react';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { addTipApi } from 'src/order/api/addTipApi';
import { useOrderStore } from 'src/order/orderStore';
import { isAddTipStep } from 'src/order/utils/isAddTipStep';
import { DefaultTips } from 'src/payment/constants/DefaultTip';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectTip(): React.ReactElement | null {
    const classes = useStyles();

    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);

    const total = useOrderStore((state) => state.total);
    const orderStep = useOrderStore((state) => state.orderStep);

    const customerDisplayScreenCustomTipEnabled = useSelector((state) => state.app.restaurant?.customerDisplayScreenCustomTipEnabled);
    const customerDisplayScreenCustomTipPercentage = useSelector((state) => state.app.restaurant?.customerDisplayScreenCustomTipPercentage);
    const customerDisplayScreenTouchEnabled = useSelector((state) => state.app.restaurant?.customerDisplayScreenTouchEnabled);
    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);

    const getTipOptions = () => {
        if (!customerDisplayScreenCustomTipEnabled || !customerDisplayScreenCustomTipPercentage) {
            return Object.values(DefaultTips);
        }

        return [...Object.values(DefaultTips), customerDisplayScreenCustomTipPercentage].sort((a, b) => a - b);
    };

    const addTip = async (tip: number) => {
        if (!customerDisplayScreenTouchEnabled || !restaurantId) return;

        setLoading(true);
        const response = await addTipApi({ restaurantId, tipPercentage: tip });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    const getTotalByPercentage = (percentage: number) => {
        return BigNumber(total ?? 0)
            .multipliedBy(percentage)
            .toFixed(2);
    };

    if (!isAddTipStep(orderStep)) return null;

    return (
        <Page title='Select tip'>
            <div className={classes.container}>
                <Text className={classes.title}>{translate('Do you want to include a tip?')}</Text>
                <div className={classes.totalContent}>
                    <Text className={classNames(classes.total, classes.textCentered)}>{formatAsCurrencyNumber(total ?? 0)}</Text>
                    <Text className={classNames(classes.text, classes.textCentered)}>{translate('Total')}</Text>
                </div>
                <section className={classes.tipOptions}>
                    {getTipOptions()?.map((optionTip) => (
                        <Button onClick={() => addTip(optionTip)} disabled={loading} variant='outline' classes={{ button: classes.tipOption }}>
                            <div className={classNames(classes.tipTotal, classes.textCentered)}>{formatAsPercentage(optionTip)}</div>
                            <div className={classes.textCentered}>{formatAsCurrencyNumber(getTotalByPercentage(optionTip))}</div>
                        </Button>
                    ))}
                    <Button variant='outline' classes={{ button: classes.tipOption }}>
                        <div className={classNames(classes.tipTotal, classes.textCentered)}>{'$'}</div>
                        <div className={classes.textCentered}>{translate('Other')}</div>
                    </Button>
                </section>
                <Button onClick={() => addTip(0)} disabled={loading} classes={{ button: classes.notTipButton }} variant='text'>
                    {translate('Do not include tip')}
                </Button>
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '10% 20px',
        height: '100dvh',
        fontFamily: theme.typography.regular,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 20,
        fontSize: 24,
        '@media (min-width: 800px)': {
            fontSize: 28,
            justifyContent: 'space-between',
        },
    },
    text: {
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        '@media (min-width: 800px)': {
            fontSize: 34,
        },
    },
    textCentered: {
        textAlign: 'center',
    },
    title: {
        fontSize: 18,
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
        '@media (min-width: 800px)': {
            fontSize: 40,
        },
    },
    tipOption: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        flex: 1,
        height: '20vh',
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 26,
        },
    },
    total: {
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
        '@media (min-width: 800px)': {
            fontSize: 40,
        },
    },
    tipTotal: {
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
        '@media (min-width: 800px)': {
            fontSize: 30,
        },
    },
    tipOptions: {
        display: 'flex',
        width: '100%',
        gap: 5,
        [theme.breakpoints.up('sm')]: {
            gap: 10,
        },
        [theme.breakpoints.up('md')]: {
            gap: 20,
        },
    },
    notTipButton: {
        width: 'max-content',
        textDecoration: 'underline',
        color: theme.palette.text.brand,
        marginTop: 20,
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 26,
        },
    },
    totalContent: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
