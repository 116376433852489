/**
 * @prettier
 */
export const Apps = Object.freeze({
    PIDEDIRECTO: 'PIDEDIRECTO',
    PIDEDIRECTO_API: 'PIDEDIRECTO_API',
    PIDEDIRECTOAPP: 'PIDEDIRECTOAPP',
    PIDEDIRECTOPOS: 'PIDEDIRECTOPOS',
    PIDEDIRECTOKIOSK: 'PIDEDIRECTOKIOSK',
    PIDEDIRECTOCDS: 'PIDEDIRECTOCDS',
    PIDEDIRECTOADMIN: 'PIDEDIRECTOADMIN',
    PIDEDIRECTOADMINAPP: 'PIDEDIRECTOADMINAPP',
    PIDEDIRECTODRIVERAPP: 'PIDEDIRECTODRIVERAPP',
    LETSEATAPP: 'LETSEATAPP',
    DIDI_FOOD: 'DIDI_FOOD',
    UBER_EATS: 'UBER_EATS',
    UBER_DAAS: 'UBER_DAAS',
    RAPPI: 'RAPPI',
    LETSEATMANAGER: 'LETSEATMANAGER', // @deprecated, use PIDEDIRECTOADMIN instead
    LETSEATDRIVERAPP: 'LETSEATDRIVERAPP', // @deprecated, use PIDEDIRECTODRIVERAPP instead
});

export type App = (typeof Apps)[keyof typeof Apps];
