/**
 * @prettier
 */
import { locale } from 'src/config/configureI18n';

export function formatAsPercentage(value?: string | number | null): string {
    if (value === undefined || value === null) {
        return '';
    }

    return Intl.NumberFormat(locale ?? 'es-MX', {
        style: 'percent',
        maximumFractionDigits: 8,
    }).format(value as any);
}
