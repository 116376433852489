/**
 * @prettier
 */
import { Auth } from 'aws-amplify';
import { ApiResponse } from 'src/api/types/ApiSauceResponse';
import type { JwtToken } from 'src/types/Id';
import { pretty } from 'src/utils/string/pretty';

export async function getJwtToken(): Promise<Result> {
    try {
        const cognitoUserSession = await Auth.currentSession();
        return { jwtToken: cognitoUserSession.getIdToken().getJwtToken() as JwtToken, errorResponse: undefined };
    } catch (e: any) {
        // all known errors
        // e === 'No current user'
        // e.code === 'NetworkError'
        // NotAuthorizedException error messages below where found in node_modules/@aws-amplify/auth/src/Auth.ts
        // search for any message below (code NotAuthorizedException is not searchable)
        // e.code === 'NotAuthorizedException' e.message === 'Access Token has been revoked'
        // e.code === 'NotAuthorizedException' e.message === 'Refresh Token has been revoked'
        // e.code === 'NotAuthorizedException' e.message === 'User is disabled.'
        // e.code === 'NotAuthorizedException' e.message === 'User does not exist.'
        // e.code === 'NotAuthorizedException' e.message === 'Refresh Token has expired'

        if (e === 'No current user') {
            console.log(`getJwtToken() error 'No current user'`);
            return {
                jwtToken: undefined,
                errorResponse: {
                    ok: false,
                    data: {
                        message: 'No current user',
                        name: 'NotAuthorizedException',
                    },
                    status: 401,
                    problem: 'CLIENT_ERROR',
                },
            };
        }
        if (e.code === 'NetworkError') {
            console.log(`getJwtToken() error NetworkError`);
            return {
                jwtToken: undefined,
                errorResponse: {
                    ok: false,
                    problem: 'NETWORK_ERROR',
                },
            };
        }
        console.log(`getJwtToken() error ${pretty({ message: e.message, code: e.code })}`);
        return {
            jwtToken: undefined,
            errorResponse: {
                ok: false,
                data: {
                    message: e.message,
                    name: e.code,
                },
                status: 401,
                problem: 'CLIENT_ERROR',
            },
        };
    }
}

type Result =
    | {
          jwtToken: JwtToken;
          errorResponse: undefined;
      }
    | {
          jwtToken: undefined;
          errorResponse: ApiResponse<{
              ok: false;
          }>;
      };
