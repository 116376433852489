/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { Divider, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useOrderStore } from 'src/order/orderStore';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function PaymentSummary(): React.ReactElement | null {
    const classes = useStyles();

    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const total = useOrderStore((state) => state.total);
    const subtotal = useOrderStore((state) => state.subtotal);
    const posDiscount = useOrderStore((state) => state.posDiscount);
    const usedCustomerCredits = useOrderStore((state) => state.usedCustomerCredits);
    const totalTip = useOrderStore((state) => state.totalTip);

    return (
        <>
            <Divider variant='dashed' />
            {subtotal && (
                <div className={classes.payment}>
                    <Text className={classes.paymentText}>{translate('Subtotal')}</Text>
                    <Text className={classes.paymentText}>{formatAsCurrencyNumber(subtotal ?? 0)}</Text>
                </div>
            )}
            {posDiscount && (
                <div className={classes.payment}>
                    <Text className={classes.paymentText}>{translate('Discount')}</Text>
                    <Text className={classes.paymentText}>{formatAsCurrencyNumber(posDiscount ?? 0)}</Text>
                </div>
            )}
            {usedCustomerCredits && (
                <div className={classes.payment}>
                    <Text className={classes.paymentText}>{translate('Credits')}</Text>
                    <Text className={classes.paymentText}>{formatAsCurrencyNumber(usedCustomerCredits ?? 0)}</Text>
                </div>
            )}
            {totalTip && (
                <div className={classes.payment}>
                    <Text className={classes.paymentText}>{translate('Tip')}</Text>
                    <Text className={classes.paymentText}>{formatAsCurrencyNumber(totalTip ?? 0)}</Text>
                </div>
            )}
            <div className={classes.payment}>
                <Text className={classes.total}>{translate('Total')}</Text>
                <Text className={classes.total}>{formatAsCurrencyNumber(total ?? 0)}</Text>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    payment: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.surface.brandHeavyContrast,
    },
    paymentText: {
        fontWeight: 400,
        fontSize: 22,
        [theme.breakpoints.up('sm')]: {
            fontSize: 26,
        },
    },
    total: {
        fontWeight: 600,
        fontSize: 30,
        [theme.breakpoints.up('sm')]: {
            fontSize: 40,
        },
    },
}));
