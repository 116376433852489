/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { APP_VERSION } from 'src/config/appVersion';
import { envBUILD_TIME } from 'src/env/envBUILD_TIME';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';

export function AppVersionInfo(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.version}>
            <div>{APP_VERSION}</div>
            {!isProductionEnvironment() && !!envBUILD_TIME() && <div>{envBUILD_TIME()}</div>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    version: {
        fontSize: isProductionEnvironment() ? '8px' : '12px',
        position: 'fixed',
        bottom: 0,
        right: 8,
        textAlign: 'right',
        color: isProductionEnvironment() ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0)',
    },
}));
