/**
 * @prettier
 */
import * as React from 'react';

export function PideDirectoSvgIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width ?? 24} height={height ?? 24} viewBox='0 0 29 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path
                d='M5.11623 11.9482C5.11623 9.5507 6.32304 7.26983 8.25641 5.9998L8.49039 5.84429L7.3082 3.83557L7.07422 3.99109C4.48819 5.68878 2.875 8.73425 2.875 11.9482C2.875 15.2529 4.54976 18.3502 7.25894 20.022L7.49292 20.1645L8.63816 18.1299L8.40418 17.9873C6.35999 16.7303 5.11623 14.4105 5.11623 11.9482Z'
                fill='#8D25FF'
            />
            <path
                d='M16.7443 3.97813L16.5098 3.83557L15.3621 5.87021L15.5966 6.01276C17.6205 7.26983 18.8792 9.57662 18.8792 12.0519C18.8792 14.4494 17.6699 16.7303 15.7324 18.0003L15.4979 18.1558L16.6826 20.1645L16.9171 20.009C19.5086 18.3113 21.1252 15.2658 21.1252 12.0519C21.1376 8.74721 19.4469 5.6499 16.7443 3.97813Z'
                fill='#8D25FF'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: number;
    height?: number;
};
