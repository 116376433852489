/**
 * @prettier
 */
import * as React from 'react';
import 'src/config/configureI18n';
import 'src/config/configureMoment';
import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { ConnectDeviceToWebSocket } from 'src/components/app/ConnectDeviceToWebSocket';
import { CustomerDisplayScreen } from 'src/scenes/CustomerDisplayScreen';
import { SignInPage } from 'src/scenes/SignInPage';
import { useIsUserSignedIn } from 'src/services/useIsUserSignedIn';

export function App(): React.ReactElement {
    const classes = useStyles();
    const [loading, isUserSignedIn] = useIsUserSignedIn();

    if (loading) {
        return (
            <div className={classes.container}>
                <Loader size={40} loading={loading} />
            </div>
        );
    }

    if (!isUserSignedIn) return <SignInPage />;

    return (
        <>
            <ConnectDeviceToWebSocket />
            <CustomerDisplayScreen />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
