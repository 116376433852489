/**
 * @prettier
 */
import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { OrderStep } from 'src/order/constants/OrderStep';
import { useOrderActions } from 'src/order/orderStore';
import { CartItemVm } from 'src/order/types/CartItemVm';
import type { RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useOrderUpdatesListener() {
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const customerDisplayScreenEnabled = useSelector((state) => state.app.restaurant?.customerDisplayScreenEnabled);

    const setOrderSummary = useOrderActions((actions) => actions.setOrderSummary);

    useEffect(() => {
        const orderChangedWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.CUSTOMER_DISPLAY_SCREEN_ORDER_UPDATED, handleOrderUpdatesWebSocketEvent);

        return () => {
            orderChangedWebSocketEvent.remove();
        };
    }, [restaurantId]);

    const handleOrderUpdatesWebSocketEvent = async (event: WebSocketEvent<Params>) => {
        if (restaurantId !== event.data?.restaurantId) return;
        if (!customerDisplayScreenEnabled) return;

        setOrderSummary({
            orderItems: event.data?.orderItems,
            totalTip: event.data?.totalTip,
            orderStep: event.data?.orderStep,
            subtotal: event.data?.subtotal,
            total: event.data?.total,
            productDiscount: event.data?.productDiscount,
            posDiscount: event.data?.posDiscount,
            posDiscountPercentage: event.data?.posDiscountPercentage,
            promoCodeSubtotal: event.data?.promoCodeSubtotal,
            promoCodeDiscount: event.data?.promoCodeDiscount,
            promoCodeCredits: event.data?.promoCodeCredits,
            promoCode: event.data?.promoCode,
            usedCustomerCredits: event.data?.usedCustomerCredits,
        });
    };
}

type Params = {
    restaurantId: RestaurantId;
    orderItems: Array<CartItemVm>;
    totalTip?: string;
    orderStep: OrderStep | undefined;
    subtotal?: string;
    total: string;
    productDiscount?: string;
    posDiscount?: string;
    posDiscountPercentage?: string;
    promoCodeSubtotal?: string;
    promoCodeDiscount?: string;
    promoCodeCredits?: string;
    promoCode?: string;
    usedCustomerCredits?: string;
};
