/**
 * @prettier
 */
import { useEffect } from 'react';
import { getAppContextApi } from 'src/api/pidedirectocds/getAppContextApi';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export function useGetAppContext(): [boolean] {
    const [loading, appContext] = useLoadApi(getAppContextApi);

    const setAppContext = useAction(appReducer.actions.setAppContext);

    useEffect(() => {
        if (appContext) {
            setAppContext(appContext);
        }
    }, [appContext]);

    return [loading];
}
