/**
 * @prettier
 */
import type { DeviceId } from 'src/types/Id';

export const KEY_NAME = 'deviceId';

export function getDeviceIdInLocalStorage(): DeviceId | undefined {
    return (window.localStorage.getItem(KEY_NAME) as DeviceId) ?? undefined;
}
