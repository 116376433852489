/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { CartItemVm, ModifierGroupVm, ModifierVm } from 'src/order/types/CartItemVm';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function CartItem({ item }: Props): React.ReactElement {
    const classes = useStyles();

    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    return (
        <article className={classes.item}>
            <div>
                <Text className={classes.quantity}>{item.quantity}</Text>
                {item.isSoldByWeight && item.salesUnit && <Text className={classes.text}>{translate(`UnitsOfMeasurement.${item.salesUnit}`)}</Text>}
            </div>
            <section className={classes.details}>
                <Text className={classes.text}>{item.name}</Text>
                {item.modifierGroups?.map((modifierGroup: ModifierGroupVm) => (
                    <div key={modifierGroup.name} className={classes.modifierGroups}>
                        {modifierGroup.showModifierGroupNameInCommand && <Text className={classes.modifierGroupName}>{modifierGroup.name}</Text>}
                        {modifierGroup.modifiers?.map((modifier: ModifierVm) => (
                            <div key={modifier.name} className={classes.modifierDetails}>
                                <Text className={classes.modifier}>{`${modifier.quantity} x ${modifier.name}`}</Text>
                                {!BigNumber(modifier.price ?? 0).isZero() && <Text className={classes.modifier}>{formatAsCurrencyNumber(modifier.price)}</Text>}
                            </div>
                        ))}
                    </div>
                ))}
            </section>
            <Text className={classes.cost}>{formatAsCurrencyNumber(item.promoUnitPrice ?? item.unitPrice)}</Text>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'grid',
        fontFamily: theme.typography.regular,
        gridTemplateColumns: '1fr 7fr 2fr',
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
    },
    text: {
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    quantity: {
        fontWeight: 600,
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
    },
    cost: {
        textAlign: 'end',
        fontWeight: 600,
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
    },
    modifier: {
        color: theme.palette.text.secondary,
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
    },
    modifierGroups: {
        color: theme.palette.text.secondary,
        display: 'flex',
        flexDirection: 'column',
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
    },
    modifierDetails: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    modifierGroupName: {
        fontWeight: 600,
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 28,
        },
    },
}));

type Props = {
    item: CartItemVm;
};
