/**
 * @prettier
 */

export function toQueryString(value: Record<string, string | number | boolean | undefined>): string {
    return Object.keys(value)
        .filter((key) => value[key] !== undefined && value[key] !== null)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent((value[key] as any)?.toString())}`)
        .join('&');
}
