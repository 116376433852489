/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { CircleIconContainer, Text } from '@pidedirecto/ui';
import { CrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useEffect } from 'react';
import { Page } from 'src/components/Page';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { useOrderActions, useOrderStore } from 'src/order/orderStore';
import { isUnSuccessfulOrder } from 'src/order/utils/isUnSuccessfulOrder';

export function UnSuccessfulOrder(): React.ReactElement | null {
    const classes = useStyles();

    const orderStep = useOrderStore((state) => state.orderStep);
    const clearOrderSummary = useOrderActions((actions) => actions.clearOrderSummary);

    useEffect(() => {
        if (isUnSuccessfulOrder(orderStep)) {
            setTimeout(() => {
                clearOrderSummary();
            }, SECONDS * 5);
        }
    }, [orderStep]);

    if (!isUnSuccessfulOrder(orderStep)) return null;

    return (
        <Page title='UnSuccessful Order'>
            <div className={classes.container}>
                <CircleIconContainer classes={{ container: classes.circleIcon }}>
                    <CrossIcon size={50} />
                </CircleIconContainer>
                <Text className={classes.text}>{translate('Failed transaction.')}</Text>
                <Text className={classes.text}>{translate('Please try again.')}</Text>
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        backgroundColor: theme.palette.surface.tertiary,
        color: theme.palette.text.primary,
        textAlign: 'center',
    },
    text: {
        display: 'block',
        fontWeight: 600,
        fontSize: 30,
        [theme.breakpoints.up('sm')]: {
            fontSize: 34,
        },
        '@media (min-width: 800px)': {
            fontSize: 48,
        },
    },
    circleIcon: {
        width: 88,
        height: 88,
        backgroundColor: '#E32F45',
        color: theme.palette.text.invert,
    },
}));
