/**
 * @prettier
 */
import { pidedirectocdsApiMethod } from 'src/api/config/pidedirectocdsApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { AppContextVm } from 'src/types/AppContextVm';

export async function getAppContextApi(): ApiSauceResponse<AppContextVm> {
    return pidedirectocdsApiMethod('getAppContextApi');
}
