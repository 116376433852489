/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { CircleIconContainer, Text } from '@pidedirecto/ui';
import { CheckIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useEffect } from 'react';
import { Page } from 'src/components/Page';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { useOrderActions, useOrderStore } from 'src/order/orderStore';
import { isSuccessfulOrderStep } from 'src/order/utils/isSuccessfulOrderStep';

export function SuccessfulOrder(): React.ReactElement | null {
    const classes = useStyles();

    const orderStep = useOrderStore((state) => state.orderStep);
    const clearOrderSummary = useOrderActions((actions) => actions.clearOrderSummary);

    useEffect(() => {
        if (isSuccessfulOrderStep(orderStep)) {
            setTimeout(() => {
                clearOrderSummary();
            }, SECONDS * 5);
        }
    }, [orderStep]);

    if (!isSuccessfulOrderStep(orderStep)) return null;

    return (
        <Page title='PayingOrder'>
            <div className={classes.container}>
                <CircleIconContainer classes={{ container: classes.circleIcon }}>
                    <CheckIcon size={50} />
                </CircleIconContainer>
                <Text className={classes.text}>{translate('Thank you for your purchase!')}</Text>
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        backgroundColor: '#1BB196',
        color: theme.palette.text.invert,
        '@media (min-width: 800px)': {
            backgroundColor: theme.palette.surface.tertiary,
            color: theme.palette.text.primary,
        },
    },
    text: {
        fontWeight: 600,
        fontSize: 30,
        [theme.breakpoints.up('sm')]: {
            fontSize: 34,
        },
        '@media (min-width: 800px)': {
            fontSize: 48,
        },
    },
    circleIcon: {
        width: 88,
        height: 88,
        color: '#1BB196',
        backgroundColor: theme.palette.surface.primary,
        '@media (min-width: 800px)': {
            backgroundColor: '#1BB196',
            color: theme.palette.text.invert,
        },
    },
}));
