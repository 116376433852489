/**
 * @prettier
 */
import { pidedirectocdsApiMethod } from 'src/api/config/pidedirectocdsApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { DeviceId, RestaurantId } from 'src/types/Id';

export async function syncDeviceApi(request: SyncDeviceApiRequest): ApiSauceResponse<void> {
    return pidedirectocdsApiMethod('device/syncDeviceApi', request);
}

type SyncDeviceApiRequest = {
    deviceId: DeviceId;
    restaurantId?: RestaurantId;
    pideDirectoCdsVersion: string;
    app: App;
    languageTag?: string;
    platform?: string;
    screenWidth?: number;
    screenHeight?: number;
    webSoundPermissionAllowed?: boolean;
};
