/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { Image } from '@pidedirecto/ui';
import { useElementSize } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useRef } from 'react';
import { Page } from 'src/components/Page';
import { CartItem } from 'src/order/components/CartItem';
import { PaymentSummary } from 'src/order/components/PaymentSummary';
import { useOrderStore } from 'src/order/orderStore';
import { CartItemVm } from 'src/order/types/CartItemVm';
import { isOrderSummaryStep } from 'src/order/utils/isOrderSummaryStep';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderSummary(): React.ReactElement | null {
    const classes = useStyles();
    const summaryRef = useRef<HTMLDivElement | null>(null);

    const { height } = useElementSize(summaryRef);

    const items = useOrderStore((state) => state.orderItems);
    const orderStep = useOrderStore((state) => state.orderStep);

    const customerDisplayScreenSecondaryPromotionBannerImageUrl = useSelector((state) => state.app.restaurant?.customerDisplayScreenSecondaryPromotionBannerImageUrl);

    if (!isOrderSummaryStep(orderStep)) return null;
    return (
        <Page title='Order Summary'>
            <div className={classes.container}>
                <div className={classNames(classes.summaryContainer, customerDisplayScreenSecondaryPromotionBannerImageUrl ? classes.summaryWithPromotionBanner : '')}>
                    <section style={{ maxHeight: `calc(100dvh - ${height + 10}px)` }} className={classes.items}>
                        {items?.map((item: CartItemVm) => <CartItem key={item.menuItemId} item={item} />)}
                    </section>
                    <section ref={summaryRef} className={classes.summary}>
                        <PaymentSummary />
                    </section>
                </div>
                {customerDisplayScreenSecondaryPromotionBannerImageUrl && (
                    <Image className={classes.image} src={customerDisplayScreenSecondaryPromotionBannerImageUrl} width={500} alt={`Promotion banner`} />
                )}
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        fontFamily: theme.typography.regular,
    },
    summaryContainer: {
        width: '100%',
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 12px',
    },
    summaryWithPromotionBanner: {
        '@media (min-width: 800px)': {
            width: '60%',
        },
    },
    image: {
        display: 'none',
        '@media (min-width: 800px)': {
            display: 'block',
            width: '40%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    items: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    summary: {
        marginTop: 'auto',
        width: '100%',
        paddingTop: 5,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 10,
        },
    },
    banner: {
        width: '100%',
        height: 'auto',
    },
}));
