/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { Image } from '@pidedirecto/ui';
import { useElementSize } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useRef } from 'react';
import { Page } from 'src/components/Page';
import { useOrderStore } from 'src/order/orderStore';
import { useSelector } from 'src/utils/react/useSelector';

export function PromotionBanner(): React.ReactElement | null {
    const classes = useStyles();
    const promotionBannerRef = useRef<HTMLDivElement | null>(null);

    const { width } = useElementSize(promotionBannerRef);
    const orderStep = useOrderStore((state) => state.orderStep);

    const customerDisplayScreenPromotionBannerImageUrl = useSelector((state) => state.app.restaurant?.customerDisplayScreenPromotionBannerImageUrl);

    if (orderStep || !customerDisplayScreenPromotionBannerImageUrl) return null;

    return (
        <Page title='Promotion Banner'>
            <div ref={promotionBannerRef}>
                <Image className={classes.image} src={customerDisplayScreenPromotionBannerImageUrl} fitIn={true} width={width} alt={`Promotion banner`} />
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        width: '100%',
    },
    image: {
        height: '100vh',
        width: '100%',
        objectFit: 'cover',
    },
}));
