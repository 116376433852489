/**
 * @prettier
 */
export const DefaultTips = {
    '10': 0.1,
    '15': 0.15,
    '20': 0.2,
} as const;

export type DefaultTip = (typeof DefaultTips)[keyof typeof DefaultTips];
