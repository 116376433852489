/**
 * @prettier
 */
import { getDeviceIdInLocalStorage } from 'src/localStorage/getDeviceIdInLocalStorage';
import { setDeviceIdInLocalStorage } from 'src/localStorage/setDeviceIdInLocalStorage';
import type { DeviceId } from 'src/types/Id';
import { newUuid } from 'src/utils/uuid/newUuid';

export function getDeviceId(): DeviceId {
    const deviceId = getDeviceIdInLocalStorage();
    if (!deviceId) {
        const newDeviceId: DeviceId = newUuid();
        setDeviceIdInLocalStorage(newDeviceId);
        return newDeviceId;
    }

    return deviceId;
}
