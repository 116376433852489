/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { useOrderStore } from 'src/order/orderStore';
import { isPayingOrderStep } from 'src/order/utils/isPayingOrderStep';

export function PayingOrder(): React.ReactElement | null {
    const classes = useStyles();
    const orderStep = useOrderStore((state) => state.orderStep);

    if (!isPayingOrderStep(orderStep)) return null;

    return (
        <Page title='PayingOrder'>
            <div className={classes.container}>
                <Loader classes={{ text: classes.text }} text={translate('Payment in progress')} loading={true} size={40}></Loader>
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.surface.tertiary,
    },
    text: {
        width: 'max-content',
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: 28,
        [theme.breakpoints.up('sm')]: {
            fontSize: 30,
        },
        '@media (min-width: 800px)': {
            fontSize: 40,
        },
    },
}));
