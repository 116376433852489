/**
 * @prettier
 */
export const es: {
    [key: string]: string;
} = {
    'Enter to PideDirecto': 'Entra a tu PideDirecto',
    'Username': 'Usuario',
    'Password': 'Contraseña',
    'Sign In': 'Iniciar Sesión',
    'By logging in you accept our': 'Al iniciar sesión aceptas nuestros',
    'Terms of use and privacy policy.': 'Términos y Condiciones y Política de Privacidad',
    'Payment in progress': 'Pago en curso',
    'Creating order': 'Creando la órden',
    'Do you want to include a tip?': '¿Deseas incluir propina?',
    'Thank you for your purchase!': '¡Gracias por tu compra!',
    'Do not include tip': 'No incluir propina',
    'Other': 'Otra',
    'Discount': 'Descuento',
    'Credits': 'Créditos',
    'Tip': 'Propina',
    'Failed transaction.': 'Transacción fallida.',
    'Please try again.': 'Por favor, intenta nuevamente.',
    'UnitsOfMeasurement.KILOGRAM': 'kg',
    'UnitsOfMeasurement.GRAM': 'gr',
    'UnitsOfMeasurement.LITRE': 'lt',
    'UnitsOfMeasurement.MILLILITER': 'ml',
    'UnitsOfMeasurement.BOX': 'cja',
    'UnitsOfMeasurement.BAG': 'bls',
    'UnitsOfMeasurement.PIECE': 'pz',
    'UnitsOfMeasurement.PACKAGE': 'paq',
    'UnitsOfMeasurement.STICK': 'brra',
    'UnitsOfMeasurement.ROLL': 'roll',
    'UnitsOfMeasurement.CAN': 'lta',
    'UnitsOfMeasurement.SLICE': 'reb',
};
