/**
 * @prettier
 */

import { pidedirectocdsApiMethod } from 'src/api/config/pidedirectocdsApiMethod';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RestaurantId } from 'src/types/Id';

export async function addTipApi(request: AddTipApiRequest): ApiSauceResponse<void> {
    return pidedirectocdsApiMethod('order/addTipApi', request);
}

type AddTipApiRequest = {
    restaurantId: RestaurantId;
    tipPercentage: number;
};
