/**
 * @prettier
 */
import type { WebSocketEventType } from 'src/constants/WebSocketEventType';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';

export class WebSocketEvents {
    static #eventListeners: Array<{
        webSocketEventType: WebSocketEventType;
        listener: WebSocketEventListener;
    }> = [];

    static addEventListener(webSocketEventType: WebSocketEventType, listener: WebSocketEventListener): EventListenerRemover {
        WebSocketEvents.#eventListeners.push({ webSocketEventType, listener });
        console.debug(`WebSocketEvents.addEventListener #eventListeners.length = ${WebSocketEvents.#eventListeners.length}`);
        return {
            remove: () => {
                WebSocketEvents.#removeEventListener(listener);
            },
        };
    }

    static #removeEventListener(listener: WebSocketEventListener): void {
        this.#eventListeners = this.#eventListeners.filter((eventListener) => eventListener.listener !== listener);
        console.debug(`WebSocketEvents.#removeEventListener #eventListeners.length = ${WebSocketEvents.#eventListeners.length}`);
    }

    static async handleWebSocketEvent(webSocketEvent: WebSocketEvent<any>): Promise<void> {
        console.debug('WebSocketEvents.handleWebSocketEvent webSocketEvent = ', webSocketEvent);
        for (const eventListener of WebSocketEvents.#eventListeners) {
            if (eventListener.webSocketEventType !== webSocketEvent.webSocketEventType) continue;
            console.debug(`WebSocketEvents.handleWebSocketEvent calling ${webSocketEvent.webSocketEventType} listener`);
            await eventListener.listener(webSocketEvent);
        }
    }
}

type WebSocketEventListener = (event: WebSocketEvent<any>) => Promise<any> | any;

export type EventListenerRemover = {
    remove: () => void;
};
