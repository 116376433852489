/**
 * @prettier
 */
export const en: {
    [key: string]: string;
} = {
    'Days.Mo': 'Monday',
    'Days.Tu': 'Tuesday',
    'Days.We': 'Wednesday',
    'Days.Th': 'Thursday',
    'Days.Fr': 'Friday',
    'Days.Sa': 'Saturday',
    'Days.Su': 'Sunday',
    'UnitsOfMeasurement.KILOGRAM': 'kg',
    'UnitsOfMeasurement.GRAM': 'gr',
    'UnitsOfMeasurement.LITRE': 'lt',
    'UnitsOfMeasurement.MILLILITER': 'ml',
    'UnitsOfMeasurement.BOX': 'cja',
    'UnitsOfMeasurement.BAG': 'bls',
    'UnitsOfMeasurement.PIECE': 'pz',
    'UnitsOfMeasurement.PACKAGE': 'paq',
    'UnitsOfMeasurement.STICK': 'brra',
    'UnitsOfMeasurement.ROLL': 'roll',
    'UnitsOfMeasurement.CAN': 'lta',
    'UnitsOfMeasurement.SLICE': 'reb',
};
