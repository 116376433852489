/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { AppSnackbar } from 'src/components/app/AppSnackbar';
import { AppVersionInfo } from 'src/components/app/AppVersionInfo';
import { EnvironmentLabel } from 'src/components/app/EnvironmentLabel';
import { SyncDevice } from 'src/components/app/SyncDevice';

export function AppContainer({ children }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {children}
            <SyncDevice />
            <AppSnackbar />
            <EnvironmentLabel />
            <AppVersionInfo />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        margin: 0,
        overflow: 'hidden',
    },
}));

type Props = {
    children: React.ReactNode;
};
