/**
 * @prettier
 */
export const OrderSteps = Object.freeze({
    ORDER_SUMMARY: 'ORDER_SUMMARY',
    ADD_TIP: 'ADD_TIP',
    CREATING_ORDER: 'CREATING_ORDER',
    UNSUCCESSFUL_ORDER: 'UNSUCCESSFUL_ORDER',
    PAYING: 'PAYING',
    SUCCESSFUL_ORDER_CREATED: 'SUCCESSFUL_ORDER_CREATED',
});

export type OrderStep = (typeof OrderSteps)[keyof typeof OrderSteps];
