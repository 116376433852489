/**
 * @prettier
 */
import { createSlice } from 'redux-starter-kit';
import type { RestaurantVm } from 'src/restaurant/types/RestaurantVm';
import type { AppContextVm } from 'src/types/AppContextVm';
import { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import { RestaurantId } from 'src/types/Id';

export const appReducer: {
    reducer: any;
    actions: Actions;
} = createSlice({
    name: 'appReducer',
    initialState: {
        snackbar: {
            isOpen: false,
            title: '',
            message: '',
            actionText: undefined,
            vertical: 'bottom',
            horizontal: 'center',
            autoHideDuration: 4000,
            onActionClick: undefined,
            onClose: undefined,
            extendedAction: undefined,
            dontCloseOnClickAway: undefined,
        },
        restaurantId: undefined,
        remainingDays: undefined,
        subscriptionAccess: undefined,
        restaurant: undefined,
    } as AppState,
    reducers: {
        showSnackbar: (
            state: AppState,
            {
                payload: config,
            }: {
                payload: {
                    title: string;
                    message: string;
                    actionText?: string;
                    vertical?: 'bottom' | 'top';
                    horizontal?: 'center' | 'left' | 'right';
                    autoHideDuration?: number;
                    onActionClick?: Function;
                    onClose?: Function;
                    dontCloseOnClickAway?: boolean;
                };
            },
        ) => {
            state.snackbar = {
                ...state.snackbar,
                ...config,
                vertical: config.vertical ?? 'bottom',
                horizontal: config.horizontal ?? 'center',
                isOpen: true,
            };
        },

        hideSnackbar: (state: AppState) => {
            state.snackbar.isOpen = false;
            state.snackbar.message = '';
            state.snackbar.actionText = undefined;
            state.snackbar.vertical = 'bottom';
            state.snackbar.horizontal = 'center';
            state.snackbar.dontCloseOnClickAway = undefined;
        },
        setAppContext: (
            state: AppState,
            {
                payload: appContext,
            }: {
                payload: AppContextVm;
            },
        ) => {
            state.restaurant = appContext.restaurant;
            state.restaurantId = appContext.restaurantId;
            state.remainingDays = appContext.remainingDays;
            state.subscriptionAccess = appContext.subscriptionAccess;
            state.deviceGroup = appContext.deviceGroup;
        },
    },
});

export type AppState = {
    restaurant: RestaurantVm | undefined;
    restaurantId: RestaurantId | undefined;
    snackbar: {
        isOpen: boolean;
        title: string;
        message: string;
        actionText?: string;
        vertical: 'bottom' | 'top';
        horizontal: 'center' | 'left' | 'right';
        autoHideDuration?: number;
        onActionClick?: Function;
        onClose?: Function;
        extendedAction?: Function;
        dontCloseOnClickAway?: boolean;
    };
    remainingDays: number | undefined;
    subscriptionAccess: boolean | undefined;
    deviceGroup?: DeviceGroupVm;
};

export type Actions = {
    setAppContext: Function;
    showSnackbar: Function;
    hideSnackbar: Function;
};
