/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { AppContainer } from 'src/components/page/AppContainer';
import { OrderSummary } from 'src/order/components/OrderSummary';
import { PayingOrder } from 'src/order/components/PayingOrder';
import { PromotionBanner } from 'src/order/components/PromotionBanner';
import { SelectTip } from 'src/order/components/SelectTip';
import { SuccessfulOrder } from 'src/order/components/SuccessfulOrder';
import { UnSuccessfulOrder } from 'src/order/components/UnSuccessfulOrder';
import { useOrderUpdatesListener } from 'src/order/services/useOrderUpdatesListener';
import { useGetAppContext } from 'src/services/useGetAppContext';

export function CustomerDisplayScreen(): React.ReactElement {
    const classes = useStyles();
    const [loading] = useGetAppContext();
    useOrderUpdatesListener();

    if (loading) {
        return (
            <div className={classes.container}>
                <Loader loading={loading} />
            </div>
        );
    }

    return (
        <AppContainer>
            <OrderSummary />
            <SelectTip />
            <PayingOrder />
            <SuccessfulOrder />
            <UnSuccessfulOrder />
            <PromotionBanner />
        </AppContainer>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100dvh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
