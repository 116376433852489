/**
 * @prettier
 */
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function useIsUserSignedIn(): [boolean, boolean] {
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Hub.listen('auth', function (data) {
            // eslint-disable-next-line default-case
            switch (data.payload.event) {
                case 'signIn': {
                    setIsUserSignedIn(true);
                    setLoading(false);
                    createUiInteractionLogEvent({
                        logEventType: LogEventTypes.USER_SIGNED_IN_TO_PIDE_DIRECTO_CDS,
                    });
                    break;
                }
                case 'signIn_failure': {
                    setIsUserSignedIn(false);
                    setLoading(false);
                    alert(data.payload.data.message ?? 'Signing in failed');
                    break;
                }
                case 'signOut': {
                    setIsUserSignedIn(false);
                    window.location.reload();
                    break;
                }
                default: {
                    setLoading(false);
                }
            }
        });
        initializeApplication();
    }, []);

    const initializeApplication = async () => {
        setLoading(true);
        try {
            await Auth.currentAuthenticatedUser();
            setIsUserSignedIn(true);
        } catch (e: any) {
            setIsUserSignedIn(false);
        } finally {
            setLoading(false);
        }
    };

    return [loading, isUserSignedIn];
}
