/**
 * @prettier
 */
import apisauce from 'apisauce';
import moment from 'moment';
import { APP_VERSION } from 'src/config/appVersion';
import { store } from 'src/config/store';
import { Apps } from 'src/constants/App';
import { envPIDEDIRECTO_CDS_API_URL } from 'src/env/envPIDEDIRECTO_CDS_API_URL';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { getSessionId } from 'src/services/device/getSessionId';
import { dateReviver } from 'src/utils/json/dateReviver';

export const pidedirectocdsApi: {
    post: any;
} = createPideDirectoCdsApiSauce(); // TODO: return api sauce type

// TODO: return api sauce type
function createPideDirectoCdsApiSauce(): {
    post: any;
} {
    const baseURL = envPIDEDIRECTO_CDS_API_URL();
    const api = apisauce.create({
        baseURL,
        headers: {
            'Content-Type': 'application/vnd.api.autoparsedates+json',
            accept: 'application/vnd.api.autoparsedates+json',
        },
        transformResponse: [(data) => JSON.parse(data, dateReviver)],
        timeout: 60000, // 60 second timeout
    });

    api.addRequestTransform((request) => {
        const state = store.getState();
        request.data._deviceId = getDeviceId();
        request.data._app = Apps.PIDEDIRECTOCDS;
        request.data._pideDirectoCdsVersion = APP_VERSION;
        request.data._restaurantId = state.app.restaurantId;
        request.data._sessionId = getSessionId();
    });

    const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
    if (isDebuggingInChrome) {
        api.addRequestTransform((request) => {
            if (mutedApiPaths.includes(request.url as string)) return;
            console.log(`${request.method?.toUpperCase()} ${baseURL}/${request.url} @ ${moment().format('HH:mm:ss.SSS')}`, request.params ?? request.data);
        });
        api.addMonitor((response) => {
            if (mutedApiPaths.includes(response.config?.url as string)) return;
            console.log(`${response.status ?? ''} <- ${response.config?.url ?? ''} @ ${moment().format('HH:mm:ss.SSS')}`, response.data);
        });
    }

    return api;
}

const mutedApiPaths = ['device/syncDeviceApi'];
