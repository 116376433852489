/**
 * @prettier
 */
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AppSnackbar(): React.ReactElement {
    const snackbar = useSelector((state) => state.app.snackbar);

    const hideSnackbar = useAction(appReducer.actions.hideSnackbar);

    const handleActionClickSnackbar = () => {
        if (snackbar.onActionClick) {
            return snackbar.onActionClick();
        }
        hideSnackbar();
    };

    const handleCloseSnackbar = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason) => {
        if (snackbar.dontCloseOnClickAway && reason === 'clickaway') {
            return;
        }
        if (snackbar.onClose) {
            return snackbar.onClose(event, reason);
        }
        hideSnackbar();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: snackbar.vertical, horizontal: snackbar.horizontal }}
            open={snackbar.isOpen}
            message={snackbar.message}
            action={
                snackbar.actionText ? (
                    <Button color='secondary' size='small' onClick={handleActionClickSnackbar}>
                        {snackbar.actionText}
                    </Button>
                ) : null
            }
            autoHideDuration={snackbar.autoHideDuration}
            onClose={handleCloseSnackbar}
        >
            {snackbar.extendedAction && (
                <Alert onClose={hideSnackbar} severity='error'>
                    <div>
                        <div>
                            <h4>{snackbar.title}</h4>
                            <p>{snackbar.message}</p>
                        </div>
                        {snackbar.actionText && (
                            <Button color='secondary' size='small' onClick={handleActionClickSnackbar}>
                                {snackbar.actionText}
                            </Button>
                        )}
                    </div>
                </Alert>
            )}
        </Snackbar>
    );
}
