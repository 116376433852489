/**
 * @prettier
 */
export const WebSocketEventTypes = {
    HEARTBEAT: 'HEARTBEAT',
    SUBSCRIBE_TO_RESTAURANT_EVENTS: 'SUBSCRIBE_TO_RESTAURANT_EVENTS',
    CUSTOMER_DISPLAY_SCREEN_ORDER_UPDATED: 'CUSTOMER_DISPLAY_SCREEN_ORDER_UPDATED',
} as const;

export type WebSocketEventType = (typeof WebSocketEventTypes)[keyof typeof WebSocketEventTypes];
