/**
 * @prettier
 */

import { makeStyles } from '@material-ui/core';
import { Loader as LoaderIndicator } from '@pidedirecto/ui';
import React from 'react';
import { usePageLoader } from 'src/services/usePageLoader';

export function Loader(): React.ReactElement | null {
    const classes = useStyles();

    const { loaderState } = usePageLoader();

    if (!loaderState.open) return null;

    return <LoaderIndicator classes={{ text: classes.text }} text={loaderState.text} loading={true} size={40} />;
}

const useStyles = makeStyles((theme) => ({
    text: {
        width: 'max-content',
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: 28,
        [theme.breakpoints.up('sm')]: {
            fontSize: 30,
        },
        '@media (min-width: 800px)': {
            fontSize: 40,
        },
    },
}));
