/**
 * @prettier
 */
export function requireBooleanString(value?: string | null): string {
    if (value === undefined) {
        throw new Error('Required value is undefined when it should be a boolean string "true" or "false"');
    }
    if (value === null) {
        throw new Error('Required value is null when it should be a boolean string "true" or "false"');
    }
    if (!(value === 'true' || value === 'false')) {
        throw new Error(`Required value is ${value} when it should be a boolean string "true" or "false"`);
    }
    return value;
}
