/**
 * @prettier
 */
import * as React from 'react';
import { useEffect } from 'react';
import { useSyncDevice } from 'src/utils/react/useSyncDevice';

export function SyncDevice(): React.ReactElement | null {
    const [syncDevice] = useSyncDevice({ callApiContinuously: true });

    useEffect(() => {
        syncDevice();
    }, []);

    return null;
}
