/**
 * @prettier
 */
import { useEffect } from 'react';

export function useInterval(callback: () => any, ms: number, deps?: Array<any>) {
    let interval: NodeJS.Timer;

    useEffect(() => {
        let cancelled = false;
        interval = setInterval(() => {
            if (cancelled) return;
            callback();
        }, ms);
        return () => {
            cancelled = true;
            clearInterval(interval);
        };
    }, deps);
}
