/**
 * @prettier
 */
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as React from 'react';
import { theme } from 'src/theme/AppTheme';

export function ThemeProvider({ children }: Props): React.ReactElement {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

type Props = {
    children: React.ReactElement;
};
