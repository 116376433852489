/**
 * @prettier
 */
import { createStore } from '@pidedirecto/ui/hooks';
import { OrderStep } from 'src/order/constants/OrderStep';
import { CartItemVm } from 'src/order/types/CartItemVm';

export const [useOrderStore, useOrderActions] = createStore<State, OrderStoreActions>({
    initialState: {
        orderItems: [],
        total: '0',
        totalTip: undefined,
        orderStep: undefined,
        usedCustomerCredits: undefined,
    },
    actions: {
        setOrderSummary: (state: State, orderSummary) => {
            state.orderItems = orderSummary.orderItems;
            state.totalTip = orderSummary.totalTip;
            state.orderStep = orderSummary.orderStep;
            state.subtotal = orderSummary.subtotal;
            state.total = orderSummary.total;
            state.productDiscount = orderSummary.productDiscount;
            state.posDiscount = orderSummary.posDiscount;
            state.posDiscountPercentage = orderSummary.posDiscountPercentage;
            state.promoCodeSubtotal = orderSummary.promoCodeSubtotal;
            state.promoCodeDiscount = orderSummary.promoCodeDiscount;
            state.promoCodeCredits = orderSummary.promoCodeCredits;
            state.promoCode = orderSummary.promoCode;
            state.usedCustomerCredits = orderSummary.usedCustomerCredits;
        },
        clearOrderSummary: (state: State) => {
            state.orderItems = [];
            state.totalTip = undefined;
            state.orderStep = undefined;
            state.subtotal = undefined;
            state.total = '0';
            state.productDiscount = undefined;
            state.posDiscount = undefined;
            state.posDiscountPercentage = undefined;
            state.promoCodeSubtotal = undefined;
            state.promoCodeDiscount = undefined;
            state.promoCodeCredits = undefined;
            state.promoCode = undefined;
            state.usedCustomerCredits = undefined;
        },
    },
});

export type State = {
    orderItems: Array<CartItemVm>;
    totalTip?: string;
    orderStep: OrderStep | undefined;
    subtotal?: string;
    total: string;
    productDiscount?: string;
    posDiscount?: string;
    posDiscountPercentage?: string;
    promoCodeSubtotal?: string;
    promoCodeDiscount?: string;
    promoCodeCredits?: string;
    promoCode?: string;
    usedCustomerCredits?: string;
};

export type OrderStoreActions = {
    setOrderSummary: (order: State) => void;
    clearOrderSummary: () => void;
};
